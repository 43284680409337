import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {map} from '@otel';
import {Policy, UserManagerExtended} from '@auth';
import {BrandConfig, IBrandService} from '@brand';

import {CountryConfig} from '../brand/types';

export enum LicenseType {
    NONE = null,
    CURACAO = 0,
    MX = 1,
    KR = 2,
    PH = 3,
    TOBIQUE = 4,
}

const licensePolicies = ['license-curacao', 'license-mx', 'license-kr', 'license-ph', 'license-tobique'] as const;
type LicensePolicy = (typeof licensePolicies)[number];

@injectable()
export class BrandService implements IBrandService<LicenseType> {
    private readonly _userManager: UserManagerExtended;

    constructor(@inject(ServiceTypes.UserManager) userManager: UserManagerExtended) {
        this._userManager = userManager;
    }

    public getUserConfig(): Observable<BrandConfig<LicenseType>> {
        const policies = this._userManager.getPolicies();

        return policies.pipe(
            map(policies => {
                const licenses: LicenseType[] = this.getLicenseFromPolicies(policies);
                return this.getAppConfig(licenses);
            })
        );
    }

    public getAppConfig(licenses: LicenseType[]): BrandConfig<LicenseType> {
        const licenseBrandMap: Record<LicenseType, BrandConfig<LicenseType>> = {
            [LicenseType.CURACAO]: this.getCuracaoConfig(),
            [LicenseType.KR]: this.getKRConfig(),
            [LicenseType.MX]: this.getMXConfig(),
            [LicenseType.PH]: this.getPHConfig(),
            [LicenseType.TOBIQUE]: this.getTobiqueConfig(),
        };

        const brandConfigs: BrandConfig<LicenseType>[] = licenses
            .map<BrandConfig<LicenseType>>(license => licenseBrandMap[license])
            .filter(b => b);

        return this.joinBrandConfigs(brandConfigs);
    }

    private joinBrandConfigs(brandConfigs: BrandConfig<LicenseType>[]): BrandConfig<LicenseType> {
        const includedCountries = brandConfigs
            .filter(brand => brand.countryConfig.mode === 'include')
            .flatMap(brand => brand.countryConfig.iso2CodeList);
        const allExludedCountries = brandConfigs
            .filter(brand => brand.countryConfig.mode === 'exclude')
            .flatMap(brand => brand.countryConfig.iso2CodeList);
        const exludedCountries = allExludedCountries.filter(country =>
            brandConfigs
                .filter(brand => brand.countryConfig.mode === 'exclude')
                .every(brand => brand.countryConfig.iso2CodeList.includes(country))
        );

        let countryConfig: CountryConfig = {
            mode: 'exclude',
            iso2CodeList: [],
        };
        if (includedCountries.length > 0 && exludedCountries.length === 0) {
            countryConfig = {
                mode: 'include',
                iso2CodeList: includedCountries,
            };
        } else {
            countryConfig = {
                mode: 'exclude',
                iso2CodeList: exludedCountries.filter(ec => !includedCountries.some(ic => ic === ec)),
            };
        }

        return {
            countryConfig,
            licenseTypes: [...new Set(brandConfigs?.flatMap(c => c.licenseTypes))],
        };
    }

    private getLicenseFromPolicies(policies: Policy[]) {
        const policyLicenseMap: Record<LicensePolicy, LicenseType> = {
            'license-curacao': LicenseType.CURACAO,
            'license-kr': LicenseType.KR,
            'license-mx': LicenseType.MX,
            'license-ph': LicenseType.PH,
            'license-tobique': LicenseType.TOBIQUE,
        };

        const licenses: LicenseType[] = Array.from(
            new Set(
                policies
                    ?.map(p => p.parse()?.module)
                    ?.filter(p => licensePolicies.find(licensePolicy => licensePolicy === p))
                    ?.map((p: LicensePolicy) => policyLicenseMap[p])
            )
        );

        return licenses;
    }

    private getCuracaoConfig(): BrandConfig<LicenseType> {
        return {
            countryConfig: {
                mode: 'include',
                iso2CodeList: [
                    'BZ',
                    'BO',
                    'CL',
                    'CR',
                    'DO',
                    'EC',
                    'SV',
                    'GT',
                    'GY',
                    'HN',
                    'HK',
                    'IE',
                    'IM',
                    'PA',
                    'PY',
                    'RS',
                    'SI',
                    'ZA',
                    'SR',
                    'UY',
                    'VE',
                ],
            },
            licenseTypes: [LicenseType.CURACAO],
        };
    }

    private getTobiqueConfig(): BrandConfig<LicenseType> {
        return {
            countryConfig: {
                mode: 'include',
                iso2CodeList: [
                    'AX',
                    'DZ',
                    'AD',
                    'AI',
                    'AQ',
                    'AG',
                    'AR',
                    'AZ',
                    'BS',
                    'BH',
                    'BD',
                    'BB',
                    'BJ',
                    'BM',
                    'BT',
                    'BA',
                    'BW',
                    'BV',
                    'BR',
                    'IO',
                    'BN',
                    'BI',
                    'CV',
                    'CA',
                    'TD',
                    'KM',
                    'CK',
                    'CI',
                    'HR',
                    'DJ',
                    'DM',
                    'EG',
                    'SZ',
                    'FK',
                    'FO',
                    'FJ',
                    'FI',
                    'GA',
                    'GM',
                    'GE',
                    'GH',
                    'GL',
                    'GD',
                    'GG',
                    'GN',
                    'HM',
                    'IS',
                    'IN',
                    'ID',
                    'JE',
                    'KZ',
                    'KI',
                    'KW',
                    'KG',
                    'LA',
                    'LR',
                    'LI',
                    'LU',
                    'MO',
                    'MW',
                    'MY',
                    'MV',
                    'MH',
                    'MR',
                    'MU',
                    'FM',
                    'MD',
                    'MC',
                    'MN',
                    'ME',
                    'MS',
                    'NA',
                    'NR',
                    'NZ',
                    'NU',
                    'MK',
                    'NO',
                    'OM',
                    'PW',
                    'PS',
                    'PG',
                    'PN',
                    'PL',
                    'QA',
                    'RW',
                    'SH',
                    'KN',
                    'LC',
                    'VC',
                    'WS',
                    'SM',
                    'ST',
                    'SA',
                    'SC',
                    'SL',
                    'SB',
                    'GS',
                    'LK',
                    'SJ',
                    'TW',
                    'TJ',
                    'TH',
                    'TL',
                    'TG',
                    'TK',
                    'TO',
                    'TT',
                    'TN',
                    'TM',
                    'TC',
                    'TV',
                    'UZ',
                    'VN',
                    'EH',
                    'ZM',
                ],
            },
            licenseTypes: [LicenseType.TOBIQUE],
        };
    }

    private getMXConfig(): BrandConfig<LicenseType> {
        return {
            countryConfig: {
                mode: 'include',
                iso2CodeList: ['MX'],
            },
            licenseTypes: [LicenseType.MX],
        };
    }

    private getPHConfig(): BrandConfig<LicenseType> {
        return {
            countryConfig: {
                mode: 'include',
                iso2CodeList: ['PH'],
            },
            licenseTypes: [LicenseType.PH],
        };
    }

    private getKRConfig(): BrandConfig<LicenseType> {
        return {
            countryConfig: {
                mode: 'include',
                iso2CodeList: ['KR', 'VN', 'TH'],
            },
            licenseTypes: [LicenseType.KR],
        };
    }
}
